<template>
    <div>
        <div class="row">
             <div class="col-sm-3">
              <el-select placeholder="Bulk Actions" v-model="bulk_select" class="website-based-list">
                  
                  <el-option v-for="(bulk, index) in bulk_list" :key="index" :label="bulk.name" :value="bulk.id">
                  </el-option>
              </el-select>
            </div>
        </div>
          <div class="row" style="margin-top:10px;">
          <div class="col-sm-12">
           
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%"   @selection-change="handleSelectionChange" v-loading="loading">              
                <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column label="Order #" sortable width="120" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini">{{scope.row.id}}</el-tag>                
                </template>
              </el-table-column>             
              <el-table-column label="Order Number" prop="customer_name" sortable width="150" >
                <template slot-scope="scope">
                    <span>{{scope.row.number}}</span>
                </template>
              </el-table-column>             
               <el-table-column label="Created Date" prop="delivery_date" sortable width="120" >
                <template slot-scope="scope">
                {{scope.row.date_created|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column> 
               <el-table-column label="Total" prop="customer_name" sortable width="100" >
                <template slot-scope="scope">
                    <span>{{scope.row.total}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Tax Total" prop="customer_name" sortable width="120" >
                <template slot-scope="scope">
                    <span>{{scope.row.total_tax}}</span>
                </template>
              </el-table-column>
                <el-table-column label="Shipping Total" prop="customer_name" sortable width="150" >
                <template slot-scope="scope">
                    <span>{{scope.row.shipping_total}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Status" prop="status" sortable >
                <template slot-scope="scope">
                      <el-select v-model="scope.row.status" placeholder="Select" clearable @change="statusChange(scope.row.id,scope.row.status)">
                          <el-option v-for="(status,index) in orderStatus" :key="index" :label="status.text" :value="status.id" auto-complete="off">
                          </el-option>
                      </el-select>  
                </template>
              </el-table-column>
            </el-table>
          </div>   
          </div>       
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
 

 export default {
    name: "list-order",
    data() {
      return {
        loading:false,
        bulk_select:null,
        order_status:null,
        list: [],
        multipleSelection: [],
        bulk_list:[
          {'id':'mark_processing', 'name':'Change status to processing'},
          {'id':'mark_on-hold', 'name':'Change status to on-hold'},
          {'id':'mark_completed', 'name':'Change status to completed'}
        ],
          orderStatus: [
            { id: "pending", text: "Pending Payment" },
            { id: "processing", text: "Processing" },
            { id: "on-hold", text: "On Hold" },
            { id: "completed", text: "Completed" },
            { id: "cancelled", text: "Cancelled" },
            { id: "refunded", text: "Refunded" },
            { id: "failed", text: "Failed" },
      ],
       
      }
    },
    watch:{
     
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Orders", route: "/order/list" },
      ]);

     
    },
    created(){
      this.getOrders();
     
    },
    methods:{
      getOrders(){
           
        var url = 'https://stagingurl.tk/cts/durham/wp-json/wc/v3/orders';
            var uname = 'ck_ccfd2acabe7597236fde10a682db47cbd616135d';
            var pass = 'cs_62a09a9a20caf643cbbe5341b5c10705cefbbc0e';
            var vm = this;
             var authorizationBasic = window.btoa(uname + ':' + pass);
                var config = {
                    "headers": {
                        "Authorization": "Basic " + authorizationBasic
                    }
                };
                
                 axios.get(url, config)
                    .then(function (response) {
                      console.log(response);
                        vm.list = response.data;
                        console.log(vm.list);
                    })
                    .catch(function (error) {
                       console.log(error);
                    })
            
           },
          handleSelectionChange(val) {
         
            this.multipleSelection = val;
            console.log(this.multipleSelection);
            
          },
          statusChange(id,order_status){
              this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
                          title: 'Please Confirm',
                          size: 'sm',
                          buttonSize: 'sm',
                          okVariant: 'danger',
                          okTitle: 'YES',
                          cancelTitle: 'NO',
                          footerClass: 'p-2',
                          hideHeaderClose: false,
                          centered: true
                        }).then(value => {
                          if(value){
                            this.loading = true;
                            var url = 'https://stagingurl.tk/cts/durham/wp-json/wc/v3/orders/'+id;
                            var uname = 'ck_ccfd2acabe7597236fde10a682db47cbd616135d';
                            var pass = 'cs_62a09a9a20caf643cbbe5341b5c10705cefbbc0e';
                          //  / var vm = this;
                            var authorizationBasic = window.btoa(uname + ':' + pass);
                                var config = {
                                    "headers": {
                                        "Authorization": "Basic " + authorizationBasic
                                    }
                                };
                                var data = {                 
                                  "status": order_status
                                }
                               axios.put(url, data, config);
                                this.loading = false;
                            // axios.put(url, data, config)
                            //         .then(function (response) {
                            //             vm.list = response.data;
                            //             console.log(vm.list);
                            //             //this.getOrders();
                            //         })
                            //         .catch(function (error) {
                            //           console.log(error);
                            //         })
       
                          }else{
                            return false;
                          }
                        })

          
            
          }
        },    
  }
</script>
